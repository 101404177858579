@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Snippet&display=swap&family=Sunflower:wght@300;500;700&display=swap');

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"), url(/public/fonts/Gilroy-Light.otf) format("opentype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  src: local("Roboto-Regular"), url(/public/fonts/Roboto-Regular.ttf) format("opentype")
}

@font-face {
  font-family: "RobotoB";
  font-weight: 900;
  src: local("Roboto-Bold"), url(/public/fonts/Gilroy-ExtraBold.otf) format("opentype")
}

@font-face {
  font-family: "Gilroy";
  font-weight: 900;
  src: local("Gilroy-Light"), url(/public/fonts/Gilroy-Light.otf) format("opentype")
}

* {
  font-family: "Kanit", sans-serif;
}

html {
  background: white;
  color: white;
  line-height: 1;
  tab-size: 4;
}

body {
  font-family: inherit;
}

html,
body,
#__next {
  min-height: 100%;
  margin: 0px;
  background-repeat: no-repeat;
}

#root {
  overflow: hidden;
}

:root {
  --app-height: 100%;
}

.checkout,
.pioneer {
  height: 100vh;
  height: var(--app-height);
}

@media only screen and (min-width: 993px) {
  body,
  html {
@import url('https://fonts.googleapis.com/css2?family=Sunflower:wght@300;500;700&display=swap');
    max-width: 100%;
  }
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  margin-block-start: 0;
  -webkit-margin-after: 1em;
  margin-block-end: 0;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  -webkit-padding-start: 28px;
  padding-inline-start: 28px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button {
  overflow: visible;
}

button:disabled {
  /* opacity: 0.5; */
  cursor: not-allowed !important;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

img {
  border: 0;
}

img,
video {
  max-width: 100%;
  height: auto;
}

input {
  line-height: normal;
  background: transparent;
  border: none;
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #333 inset !important;
  -webkit-text-fill-color: #FFF !important;
  caret-color: white;
}

a {
  outline: none;
}

input::placeholder {
  color: white;
}

*,
::before,
::after {
  box-sizing: border-box;
}

h1,
h2 {
  line-height: 110%;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

@keyframes mouse-scroll {
  0% {
    transform: translate(50%, 0px);
  }

  50% {
    transform: translate(50%, -10px);
  }

  100% {
    transform: translate(50%, 0px);
  }
}

article {
  font-weight: 700;
  font-size: 2.813em;
  line-height: 55px;
  letter-spacing: -0.05em;
}

@media (max-width: 768px) {
  article {
    font-size: 2.625em;
    line-height: 51px;
  }
}

@media (max-width: 360px) {
  article {
    font-size: 2.25em;
    line-height: 45px;
  }
}

@media (max-width: 320px) {
  article {
    font-size: 1.375em;
    line-height: 31px;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: rgb(238, 234, 244) 0px 0px 5px inset;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  height: 30px;
  background-color: #070818 !important;
  border-radius: 8px;
}

@media (min-width: 576px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.current {
  background-clip: content-box, border-box;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 5px;
  text-align: center;
}

.navbar-toggler-icon {
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (min-width: 1200px) {
  .timeline {
    width: 150px !important;
  }
}

@media (max-width: 1400px) {
  .ecosystem-container {
    padding-left: 320px;
  }
  .eco-curve {
    left: -100px;
  }
}

@media (max-width: 1300px) {
  .ecosystem-container {
    padding-left: 120px;
  }
  .eco-img {
    width: 500px;
    top: 20px;
  }
  .eco-curve {
    left: -300px;
  }
}

@media (max-width: 1100px) {
  .eco-img {
    width: 400px;
    top: 60px;
  }
}

@media (max-width: 900px) {
  .ecosystem-container {
    padding-left: 320px;
    padding-right: 20px;
  }
  .eco-curve {
    left: -100px;
  }
}

@media (max-width: 768px) {
  .ecosystem-container {
    padding-left: 120px;
  }
  .eco-curve {
    left: -300px;
  }
}

@media (max-width: 560px) {
  .ecosystem-container {
    padding-left: 20px;
  }
}